<template>
  <div>
    <div class="registers">
      <div class="card">
        <div class="card-cont">
          <h2>用户注册</h2>
          <el-row :gutter="10" class="row">
            <el-col :span="10">
              <el-form
                :model="ruleForm"
                status-icon
                :rules="rules"
                ref="ruleForm"
                label-width="100px"
                class="demo-ruleForm"
                label-position="left"
              >
                <el-form-item label="企业名称:" prop="name">
                  <el-input
                    v-model.trim="ruleForm.name"
                    autocomplete="off"
                  ></el-input>
                </el-form-item>

                <el-form-item label="手机号码:" prop="tel">
                  <el-input
                    v-model.trim="ruleForm.tel"
                    @blur="inspectPhone"
                    autocomplete="off"
                  ></el-input>
                </el-form-item>

                <el-form-item label="创建密码:" prop="pass">
                  <el-input
                    show-password
                    type="password"
                    v-model.trim="ruleForm.pass"
                    autocomplete="off"
                  ></el-input>
                </el-form-item>
                <el-form-item label="确认密码:" prop="checkPass">
                  <el-input
                    show-password
                    type="password"
                    v-model.trim="ruleForm.checkPass"
                    autocomplete="off"
                  ></el-input>
                </el-form-item>
                <!--
                                <el-form-item label="图形码:" prop="graphic">
                                  <el-row :gutter="10">
                                    <el-col :span="12">
                                      <el-input
                                        class="ipts"
                                        placeholder="请输入图形码"
                                        v-model.trim="ruleForm.graphic"
                                      >
                                        <i slot="prefix" class="iconfont icon-zhiliang"></i>
                                      </el-input>
                                    </el-col>
                                    <el-col :span="8">
                                      <img
                                        src=""
                                        alt="图片生成中..."
                                        title="看不清楚? 点击更换"
                                      />
                                    </el-col>
                                  </el-row>
                                </el-form-item> -->

                <el-form-item label="验证码:" prop="verification">
                  <el-row :gutter="10">
                    <el-col :span="12" style="padding-left: 0; width: 65%">
                      <el-input
                        placeholder="请输入验证码"
                        v-model.trim="ruleForm.verification"
                      >
                      </el-input>
                    </el-col>
                    <el-col :span="8">
                      <!-- <div class="box" v-html="html"></div> -->
                      <el-button
                        style="width: 100%"
                        type="primary"
                        v-html="html"
                        :disabled="disabled"
                        @click="getVer"
                      ></el-button>
                    </el-col>
                  </el-row>
                </el-form-item>
              </el-form>

              <div class="bottom">
                <el-checkbox class="sogin" v-model="ruleForm.checked"
                  >我已同意
                </el-checkbox>
                <span @click="dialogVisible = true"
                  >《隐私保护和用户协议》</span
                >
              </div>
              <div class="btn">
                <el-button
                  :disabled="disabledBtn"
                  class="btns"
                  type="primary"
                  @click="submitForm('ruleForm')"
                  v-loading.fullscreen.lock="fullscreenLoading"
                  >立即注册</el-button
                >
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
    <!-- 协议对话框 -->
    <el-dialog
      title="隐私保护和用户协议"
      :visible.sync="dialogVisible"
      width="600px"
      center
    >
      <div class="el-dialog-div">
        <p class="first">
          本网站非常重视用户的隐私保护，因此制定了本涵盖如何收集、使用、披露、分享以及存储用户的信息的《隐私条款》。
          用户在使用我们的服务时，我们可能会收集和使用您的相关信息。我们希望通过本《隐私条款》向您说明，在使用我们的服务时，
          我们如何收集、使用、储存和分享这些信息，以及我们为您提供的访问、更新、控制和保护这些信息的方式。
          本《隐私条款》适用于用户与本网站的交互行为以及用户注册和使用本网站的服务，我们建议您仔细地阅读本政策，以帮助您了解维护自己隐私权的方式。
          您使用或继续使用我们的服务，即表示您同意我们按照本《隐私条款》收集、使用、储存和分享您的相关信息。
          如对本《隐私条款》或相关事宜有任何问题，请进行留言。
        </p>
        <p>
          一、我们可能收集的信息我们提供服务时，可能会收集、储存和使用下列与您有关的信息。如果您不提供相关信息，
          可能无法注册成为我们的用户或无法享受我们提供的某些服务，或者无法达到相关服务拟达到的效果。
        </p>
        <h4>（一）您提供的信息</h4>
        <p>
          1、您在注册账户或使用我们的服务时，向我们提供的相关个人信息，例如电话号码、身份证明、电子邮件、银行账号信息、营业执照，以及相关资格证书等；
          2、如您使用我们的服务需与用户的银行账户或其他支付工具的账户关联方能实现时，您向我们提供您的银行账户信息或其他支付工具的账户信息；
          3、您通过我们的服务向其他方提供的共享信息，以及您使用我们的服务时所储存的信息。
        </p>
        <h4>（二）其他方分享的您的信息</h4>
        <p>其他方使用我们的服务时所提供有关您的共享信息。</p>
        <h4>（三）我们获取的您的信息</h4>
        <p>
          1、您使用服务时我们可能收集如下信息：
          （1）日志信息，指您使用我们的服务时，系统自动采集的技术信息，包括： o
          设备或软件信息，例如您的移动设备、网页浏览器或用于接入我们服务的其他程序所提供的配置信息、您的IP地址和移动设备所用的版本和设备识别码；
          o
          在使用我们服务时搜索或浏览的信息，例如您使用的网页搜索词语、访问的社交媒体页面url地址，以及您在使用我们服务时浏览或要求提供的其他信息和内容详情；
          o
          有关您曾使用的移动应用（APP）和其他软件的信息，以及您曾经使用该等移动应用和软件的信息；
          o
          您通过我们的服务进行通讯的信息，例如曾通讯的账号，以及通讯时间、数据和时长；
          o
          您通过我们的服务分享的内容所包含的信息（元数据），例如拍摄或上传的共享照片或录像的日期、时间或地点等。
          （2）位置信息，指您开启设备定位功能并使用我们基于位置提供的相关服务时，收集的有关您位置的信息，包括：
          o
          您或其他用户提供的包含您所处地理位置的实时信息，例如您提供的账户信息中包含的您所在地区信息，您或其他人上传的显示您当前或曾经所处地理位置的共享信息，您或其他人共享的照片包含的地理标记信息；
          o
          您通过具有定位功能的移动设备使用我们的服务时，通过GPS或WiFi等方式收集的您的地理位置信息；
          o 您可以通过关闭定位功能，停止对您的地理位置信息的收集。
        </p>
        <h4>二、我们如何使用您的信息</h4>
        <p>
          （一）我们可能将在向您提供服务的过程之中所收集的信息用作下列用途：
          1、向您提供服务。在我们提供服务时，用于身份验证、客户服务、安全防范、诈骗监测、存档和备份用途，确保我们向您提供的产品和服务的安全性；
          2、帮助我们设计新服务，改善我们现有服务；
          3、使我们更加了解您如何接入和使用我们的服务，从而针对性地回应您的个性化需求，例如语言设定、位置设定、个性化的帮助服务和指示，或对您和其他用户作出其他方面的回应；
          4、向您提供与您更加相关的广告以替代普遍投放的广告；
          5、评估我们服务中的广告和其他促销及推广活动的效果，并加以改善；
          6、软件认证或管理软件升级； 7、让您参与有关我们产品和服务的调查。
        </p>
        <p>
          （二）为了让您有更好的体验、改善我们的服务或您同意的其他用途，在符合相关法律法规的前提下，我们可能将通过某一项服务所收集的信息，以汇集信息或者个性化的方式，用于我们的其他服务。例如，在您使用我们的一项服务时所收集的信息，可能在另一服务中用于向您提供特定内容，
          或向您展示与您相关的、非普遍推送的信息。如果我们在相关服务中提供了相应选项，您也可以授权我们将该服务所提供和储存的信息用于我们的其他服务。
        </p>
        <h4>三、您如何访问和控制自己的个人信息</h4>
        <p>
          我们将尽量采取适当的技术手段，保证您可以访问、更新和更正自己的注册信息或使用我们的服务时提供的其他个人信息。在访问、更新、更正和删除前述信息时，我们可能会要求您进行身份验证，以保障账户安全。
        </p>
        <h4>四、我们如何分享您的信息</h4>
        <p class="first">
          除以下情形外，未经您同意，我们以及我们的关联公司不会与任何第三方分享您的个人信息：
          （一）我们以及我们的关联公司，可能将您的个人信息与我们的关联公司、合作伙伴及第三方服务供应商、承包商及代理（例如代表我们发出电子邮件或推送通知的通讯服务提供商、为我们提供位置数据的地图服务供应商）分享（他们可能并非位于您所在的法域），用作下列用途：
          o 向您提供我们的服务； o 实现“我们可能如何使用信息”部分所述目的； o
          履行我们在本《隐私条款》或本网站与您达成的其他协议中的义务和行使我们的权利；
          o 理解、维护和改善我们的服务。
          如我们或我们的关联公司与任何上述第三方分享您的个人信息，我们将努力确保该等第三方在使用您的个人信息时遵守本《隐私条款》及我们要求其遵守的其他适当的保密和安全措施。
          （二）随着我们业务的持续发展，我们以及我们的关联公司有可能进行合并、收购、资产转让或类似的交易，您的个人信息有可能作为此类交易的一部分而被转移。我们将在转移前通知您。
          （三）我们或我们的关联公司还可能为以下需要而保留、保存或披露您的个人信息：
          o 您授权或同意本网站披露的； o 遵守适用的法律法规； o
          遵守法院命令或其他法律程序的规定； o 遵守相关政府机关的要求； o
          为遵守适用的法律法规、维护社会公共利益，或保护我们的客户、我们或我们的集团公司、其他用户或雇员的人身和财产安全或合法权益所合理必需的用途；
          o
          根据本网站各服务条款及声明中的相关规定，或者本网站认为必要的其他情形下。
        </p>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false"
          >关闭</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
// import loginApi from "../../api/login/login";
import api from "../../api/index/index";
export default {
  name: "Register",
  data() {
    // 手机验证
    // var validateMobilePhone = (rule, value, callback) => {
    //   if (value === "") {
    //     callback(new Error("手机号不可为空"));
    //   } else {
    //     if (value !== "") {
    //       var reg = /^1[3456789]\d{9}$/;
    //       if (!reg.test(value)) {
    //         callback(new Error("请输入有效的手机号码"));
    //       }
    //     }
    //     callback();
    //   }
    // };
    // 密码验证
    var validatePass = (rule, value, callback) => {
      const pwdReg = /^(?=.*\d)(?=.*[a-zA-Z]).{8,}$/;
      if (!value) {
        return callback(new Error("请输入密码"));
        } else if (!pwdReg.test(value)) {
         callback(new Error("请输入包含数字字母的不低于8位数的密码"));
       } else {
        callback();
      }

    };
    // 二次密码验证
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.ruleForm.pass) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      disabledBtn: false,
      dialogVisible: false,
      disabled: false, // 按钮禁用
      html: "获取验证码",
      ruleForm: {
        tel: "", // 手机号
        pass: "", // 密码
        checkPass: "", // 二次确认密码
        // graphic: "", // 图形码
        verification: "", //验证码
        checked: false, //协议验证
        name: "", //企业名称
      },
      // 表单验证
      rules: {
        // tel: [
        //   {
        //     required: true,
        //     validator: validateMobilePhone,
        //     trigger: "blur",
        //   },
        // ],
        tel: [
          {
            required: true,
            message: "账号或者手机号不能为空",
            trigger: "blur",
          },
        ],
        name: [{ required: true, message: "请输入企业名称", trigger: "blur" }],
        pass: [{ required: true, validator: validatePass, trigger: "blur" }],

        checkPass: [
          { required: true, validator: validatePass2, trigger: "blur" },
        ],
        verification: [
          { required: true, message: "请输入验证码", trigger: "blur" },
        ],
        // graphic: [{ required: true, message: "请输入验证码", trigger: "blur" }],
      },
      //指令加载方式
      fullscreenLoading: false,
    };
  },
  methods: {
    // 手机号是否存在校验
    inspectPhone() {
      if (this.ruleForm.tel !== "") {
        api
          .inspectPhones(this.ruleForm.tel)
          .then((res) => {
            console.log("检测手机号是否存在", res);
            if (res.success) {
              if (res.data === 1) {
                // this.$message.warning("当前账号已注册，请登录");
                this.$confirm("当前账号已注册，请确定是否跳转登录", "提示", {
                  confirmButtonText: "确定",
                  cancelButtonText: "取消",
                  type: "warning",
                })
                  .then(() => {
                    this.$router.push({ path: "/login" });
                    this.ruleForm.tel = "";
                  })
                  .catch(() => {
                    return;
                  });
                this.disabledBtn = this.disabled = true;

                // this.$router.push({ path: "/login" });
                // this.ruleForm.tel = "";
              } else {
                this.disabledBtn = this.disabled = false;
              }
            }
          })
          .catch((error) => {
            console.error("error", error);
          });
      }
    },
    //获取验证码
    getVer() {
      // 倒计时
      // this.disabled = true;
      // var cont = 5;

      let datas = this.ruleForm;
      console.log(this.ruleForm.tel);
      let data = {
        phone: datas.tel,
        management: "mallUse",
      };

      if (data.phone == "") {
        this.$message({
          message: "手机号不能为空",
          type: "warning",
        });
        // this.disabled = true;
      } else {
        //获取验证码
        this.$http
          .post(
            "/login/sendSms?phone=" +
              data.phone +
              "&management=" +
              data.management
          )
          .then((res) => {
            console.log(res);
            if (res.data.code == 200) {
              console.log(data);
              this.$message({
                message: "获取成功",
                type: "success",
              });
              var cont = 60;
              let timer = setInterval(() => {
                --cont;
                if (cont === 0) {
                  this.disabled = false;
                  this.html = "获取验证码";
                  clearInterval(timer);
                  return;
                } else {
                  this.html = `${cont}秒后重新获取`;
                }
              }, 1000);
            } else {
              this.$message({
                message: '短信发送失败,请重试',
                type: "warning",
              });
            }
          });
      }
    },

    // 注册
    submitForm(ruleForm) {
      // 表单验证
      this.$refs[ruleForm].validate((valid) => {
        if (valid) {
          if (this.ruleForm.checked) {
            console.log(this.ruleForm);
            let datas = this.ruleForm;
            let data = {
              code: datas.verification,
              userName: datas.name,
              phone: datas.tel,
              pwd: datas.pass,
            };

            //注册

            this.fullscreenLoading = true;
            setTimeout(() => {
              this.$http.post("/login/registeredUser", data).then((res) => {
                console.log(res);
                this.fullscreenLoading = false;
                if (res.data.code == 200) {
                  console.log(data);
                  this.$message({
                    message: "注册成功",
                    type: "success",
                  });
                  this.$router.push({ path: "/login" });
                } else {
                  this.$message({
                    message: res.data.message,
                    type: "warning",
                  });
                }
              });
            }, 2000);
          } else {
            this.$message({
              message: "请同意隐私协议",
              type: "warning",
            });
          }
        } else {
          // console.log("登录失败");
          // alert("注册失败");
          this.$message.error("注册失败");
          return false;
        }
      });
    },
  },
};
</script>
<style lang="scss">
.el-dialog-div {
  height: 300px;
  overflow: auto;

  .first {
    text-indent: 2em;
  }
}

.el-dialog--center {
  border-radius: 20px;
}

.el-dialog__title {
  font-weight: 600;
}

.el-form--label-left .el-form-item__label {
  /*color: #fff;*/
  text-align: justify;
  text-align-last: justify;
}

.el-col-8 {
  .el-button {
    padding: 12px 0;
  }
}
</style>
<style lang="scss" scoped>
/*.sogin {
  color: #fff;
}*/

.registers {
  width: 100%;
  background-color: #f2f2f2;
  padding: 40px 0;

  .card {
    width: 1200px;
    margin: 0 auto;
    height: 500px;
    background-color: #fff;
    text-align: center;
    overflow: hidden;
    /*background: url("../../static/imgs/aviodPhoney/bg_aviodPhoney_03.jpg")*/
    /*  no-repeat center;*/
    h2 {
      margin-top: 30px;
    }

    .row {
      width: 100%;
      display: flex;
      justify-content: center;
    }

    .demo-ruleForm {
      margin-top: 30px;
    }

    img {
      display: block;
      width: 120px;
      height: 40px;
      border: 1px solid #808080;
      cursor: pointer;
    }

    .btn {
      margin-top: 10px;
      width: 100%;
      text-align: center;
      height: 40px;
      line-height: 40px;
      // background-color: #0289f3;
      color: #fff;
      font-weight: 600;
      border-radius: 10px;
      cursor: pointer;
      .btns {
        width: 100%;
      }
    }

    .bottom {
      display: flex;

      span {
        display: inline-block;
        color: #0289f3;
        font-size: 14px;
        cursor: pointer;
      }
    }
  }
}
</style>
